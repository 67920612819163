<template>
  <div>
    <v-dialog v-model="dialog" width="600" persistent scrollable>
      <v-card>
        <v-card-title>
          {{ form.id ? 'Modifier un POS' : 'Ajouter un POS' }}
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-4">
          <form @submit.prevent="save">
            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field id="key_26"
                              :label="keyword.key_26"
                              hide-details="auto"
                              @input="errors && errors.code ? errors.code = '' : false"
                              :error-messages="errors.code" v-model="form.code"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_27"
                              :label="keyword.key_27"
                              hide-details="auto"
                              @input="errors && errors.name ? errors.name = '' : false"
                              :error-messages="errors.name" v-model="form.name"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_1"
                              :label="keyword.key_1"
                              hide-details="auto"
                              @input="errors && errors.type ? errors.type = '' : false"
                              :error-messages="errors.type" v-model="form.type"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_28"
                              :label="keyword.key_28"
                              hide-details="auto"
                              @input="errors && errors.phone ? errors.phone = '' : false"
                              :error-messages="errors.phone" v-model="form.phone"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_29"
                              :label="keyword.key_29"
                              hide-details="auto"
                              @input="errors && errors.canal ? errors.canal = '' : false"
                              :error-messages="errors.canal" v-model="form.canal"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_30"
                              :label="keyword.key_30"
                              hide-details="auto"
                              @input="errors && errors.benchmark_institution ? errors.benchmark_institution = '' : false"
                              :error-messages="errors.benchmark_institution" v-model="form.benchmark_institution"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_2"
                              :label="keyword.key_2"
                              hide-details="auto"
                              @input="errors && errors.region ? errors.region = '' : false"
                              :error-messages="errors.region" v-model="form.region"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-autocomplete id="key_3"
                                :label="keyword.key_3"
                                chips
                                small-chips
                                deletable-chips
                                hide-details="auto"
                                @input="errors && errors.city ? errors.city = '' : false"
                                :error-messages="errors.city" v-model="form.city"
                                :items="cities"
                                item-text="name" item-value="name">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_4"
                              :label="keyword.key_4"
                              hide-details="auto"
                              @input="errors && errors.municipality ? errors.municipality = '' : false"
                              :error-messages="errors.municipality" v-model="form.municipality"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_40"
                              :label="keyword.key_40"
                              hide-details="auto"
                              @input="errors && errors.address ? errors.address = '' : false"
                              :error-messages="errors.address" v-model="form.address"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_31"
                              :label="keyword.key_31"
                              hide-details="auto"
                              @input="errors && errors.landmark ? errors.landmark = '' : false"
                              :error-messages="errors.landmark" v-model="form.landmark"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_5"
                              :label="keyword.key_5"
                              hide-details="auto"
                              @input="errors && errors.role_b ? errors.role_b = '' : false"
                              :error-messages="errors.role_b" v-model="form.role_b"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_32"
                              :label="keyword.key_32"
                              hide-details="auto"
                              @input="errors && errors.coverage_b ? errors.coverage_b = '' : false"
                              :error-messages="errors.coverage_b" v-model="form.coverage_b"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_33"
                              :label="keyword.key_33"
                              hide-details="auto"
                              @input="errors && errors.last_call_b ? errors.last_call_b = '' : false"
                              :error-messages="errors.last_call_b" v-model="form.last_call_b"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_34"
                              :label="keyword.key_34"
                              hide-details="auto"
                              @input="errors && errors.number_call_b ? errors.number_call_b = '' : false"
                              :error-messages="errors.number_call_b" v-model="form.number_call_b"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_6"
                              :label="keyword.key_6"
                              hide-details="auto"
                              @input="errors && errors.role_c ? errors.role_c = '' : false"
                              :error-messages="errors.role_c" v-model="form.role_c"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_35"
                              :label="keyword.key_35"
                              hide-details="auto"
                              @input="errors && errors.coverage_c ? errors.coverage_c = '' : false"
                              :error-messages="errors.coverage_c" v-model="form.coverage_c"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_36"
                              :label="keyword.key_36"
                              hide-details="auto"
                              @input="errors && errors.last_call_c ? errors.last_call_c = '' : false"
                              :error-messages="errors.last_call_c" v-model="form.last_call_c"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_37"
                              :label="keyword.key_37"
                              hide-details="auto"
                              @input="errors && errors.number_call_c ? errors.number_call_c = '' : false"
                              :error-messages="errors.number_call_c" v-model="form.number_call_c"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_38"
                              :label="keyword.key_38"
                              hide-details="auto"
                              @input="errors && errors.tip ? errors.tip = '' : false"
                              :error-messages="errors.tip" v-model="form.tip"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-checkbox hide-details="auto"
                            v-model="form.salepoint_platinum"
                            id="key_7"
                            :label="keyword.key_7"
                            @change="errors && errors.salepoint_platinum ? errors.salepoint_platinum = '' : false"
                            :error-messages="errors.salepoint_platinum"></v-checkbox>
              </v-col>
              <v-col cols="12" lg="6">
                <v-select :items="['A','B','C','D']"
                          id="key_8"
                          :label="keyword.key_8"
                          hide-details="auto"
                          @change="errors && errors.class ? errors.class = '' : false"
                          :error-messages="errors.class" v-model="form.class"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_30"
                              :label="keyword.key_30"
                              hide-details="auto"
                              @input="errors && errors.declare_by ? errors.declare_by = '' : false"
                              :error-messages="errors.declare_by" v-model="form.declare_by"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_10"
                              :label="keyword.key_10"
                              hide-details="auto"
                              @input="errors && errors.sum_of_M_106 ? errors.sum_of_M_106 = '' : false"
                              :error-messages="errors.sum_of_M_106" v-model="form.sum_of_M_106"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_11"
                              :label="keyword.key_11"
                              hide-details="auto"
                              @input="errors && errors.sum_of_M_70 ? errors.sum_of_M_70 = '' : false"
                              :error-messages="errors.sum_of_M_70" v-model="form.sum_of_M_70"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_12"
                              :label="keyword.key_12"
                              hide-details="auto"
                              @input="errors && errors.sum_of_ULT ? errors.sum_of_ULT = '' : false"
                              :error-messages="errors.sum_of_ULT" v-model="form.sum_of_ULT"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_13"
                              :label="keyword.key_13"
                              hide-details="auto"
                              @input="errors && errors.sum_of_HSP ? errors.sum_of_HSP = '' : false"
                              :error-messages="errors.sum_of_HSP" v-model="form.sum_of_HSP"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_14"
                              :label="keyword.key_14"
                              hide-details="auto"
                              @input="errors && errors.sum_of_Con92_1 ? errors.sum_of_Con92_1 = '' : false"
                              :error-messages="errors.sum_of_Con92_1" v-model="form.sum_of_Con92_1"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_15"
                              :label="keyword.key_15"
                              hide-details="auto"
                              @input="errors && errors.sum_of_Con92_2 ? errors.sum_of_Con92_2 = '' : false"
                              :error-messages="errors.sum_of_Con92_2" v-model="form.sum_of_Con92_2"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_16"
                              :label="keyword.key_16"
                              hide-details="auto"
                              @input="errors && errors.sum_of_OV_GM_1 ? errors.sum_of_OV_GM_1 = '' : false"
                              :error-messages="errors.sum_of_OV_GM_1" v-model="form.sum_of_OV_GM_1"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_17"
                              :label="keyword.key_17"
                              hide-details="auto"
                              @input="errors && errors.sum_of_OV_GM_2 ? errors.sum_of_OV_GM_2 = '' : false"
                              :error-messages="errors.sum_of_OV_GM_2" v-model="form.sum_of_OV_GM_2"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_18"
                              :label="keyword.key_18"
                              hide-details="auto"
                              @input="errors && errors.sum_of_OV_PM ? errors.sum_of_OV_PM = '' : false"
                              :error-messages="errors.sum_of_OV_PM" v-model="form.sum_of_OV_PM"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_19"
                              :label="keyword.key_19"
                              hide-details="auto"
                              @input="errors && errors.sum_of_Disps ? errors.sum_of_Disps = '' : false"
                              :error-messages="errors.sum_of_Disps" v-model="form.sum_of_Disps"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_20"
                              :label="keyword.key_20"
                              hide-details="auto"
                              @input="errors && errors.sum_of_Waf_66 ? errors.sum_of_Waf_66 = '' : false"
                              :error-messages="errors.sum_of_Waf_66" v-model="form.sum_of_Waf_66"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_21"
                              :label="keyword.key_21"
                              hide-details="auto"
                              @input="errors && errors.sum_of_Waf_99 ? errors.sum_of_Waf_99 = '' : false"
                              :error-messages="errors.sum_of_Waf_99" v-model="form.sum_of_Waf_99"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_22"
                              :label="keyword.key_22"
                              hide-details="auto"
                              @input="errors && errors.sum_of_M_M ? errors.sum_of_M_M = '' : false"
                              :error-messages="errors.sum_of_M_M" v-model="form.sum_of_M_M"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_23"
                              :label="keyword.key_23"
                              hide-details="auto"
                              @input="errors && errors.sum_of_M_C1 ? errors.sum_of_M_C1 = '' : false"
                              :error-messages="errors.sum_of_M_C1" v-model="form.sum_of_M_C1"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_24"
                              :label="keyword.key_24"
                              hide-details="auto"
                              @input="errors && errors.sum_of_M_C2 ? errors.sum_of_M_C2 = '' : false"
                              :error-messages="errors.sum_of_M_C2" v-model="form.sum_of_M_C2"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_25"
                              :label="keyword.key_25"
                              hide-details="auto"
                              @input="errors && errors.sum_of_M_C3 ? errors.sum_of_M_C3 = '' : false"
                              :error-messages="errors.sum_of_M_C3" v-model="form.sum_of_M_C3"/>
              </v-col>
            </v-row>
            <div class="d-flex justify-space-between mt-8">
              <v-spacer/>
              <v-btn color="primary" :loading="btnLoading" depressed class="rounded-lg"
                     type="submit">
                <v-icon left>mdi-content-save</v-icon>
                Enregistrer
              </v-btn>
            </div>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
  props: ['dialog'],
  data() {
    return {
      btnLoading: false,
      form: {},
      errors: {},
    }
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
    },
    editItem(item) {
      this.form = item
      this.errors = {}
    },
    addItem() {
      this.form = {
        salepoint_platinum: false
      }
      this.errors = {}
    },
    save() {
      this.btnLoading = true
      const url = this.form.id ? '/database/update' : '/database/store'
      HTTP.post(url, this.form).then(() => {
        this.$successMessage = 'Cet POS a été enregistrée avec succès'
        this.$emit('get-salepoints')
        this.close()
        this.btnLoading = false
      }).catch(err => {
        this.btnLoading = false
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        console.log(err)
      })
    },
  },
  computed: {
    keyword() {
      return this.$store.state.keyword
    },
    cities() {
      return this.$store.state.cities
    }
  }
}
</script>

<style scoped>

</style>