<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="500">
      <form @submit.prevent="save">
        <v-card :disabled="btnLoading" :loading="btnLoading">
          <v-card-title>
            Importer des comptes TIP
            <v-spacer/>
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>

           <v-card-subtitle>
               Cette opération peut créer ou mettre à jour s'il existe.
         </v-card-subtitle>

          <v-divider/>
          
          <v-card-text class="pa-4">
            
            <v-file-input ref="file"
                          :error-messages="errors.excel_file"
                          dense
                          filled
                          label="Fichier excel *"
                          rounded
                          @change="uploadFile($event)"></v-file-input>
            
            <v-alert border="left"
                     color="info"
                     prominent
                     text>
              Veuillez respecter ce format excel :
              
              <span class="d-block py-2"><strong>Colonne A :</strong> Code POS </span>
              <span class="d-block py-2"><strong>Colonne B :</strong> N°Téléphone </span>
              <span class="d-block py-2"><strong>Colonne C :</strong> Gifty ID </span>
              <span class="d-block py-2"><strong>Colonne D :</strong> Nom POS </span>
              <span class="d-block py-2"><strong>Colonne E :</strong> Type</span>
              <span class="d-block py-2"><strong>Colonne F :</strong> Wilaya</span>
              <span class="d-block py-2"><strong>Colonne J :</strong> Mot de passe </span>
              
              <v-btn class="mt-2" color="info"
                     dark
                     depressed
                     @click="download">
                <v-icon left>mdi-download-outline</v-icon>
                Exemple
              </v-btn>
            </v-alert>
          
          </v-card-text>
          
          <v-divider/>
          
          <v-card-actions>
            <v-spacer/>
            <v-btn :loading="btnLoading"
                   class="rounded-lg"
                   color="primary"
                   depressed
                   type="submit"
            >
              <v-icon left>mdi-content-save</v-icon>
              importer
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            dialog: false,
            btnLoading: false,
            excel_file: '',
            errors: {},
        };
    },
    methods: {
        open() {
            if (this.$refs.file) {
                this.$refs.file.reset();
                this.excel_file = '';
            }
            this.errors = {};
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
        save() {
            this.errors = {};
            this.btnLoading = true;
            let formData = new FormData();
            formData.append('excel_file', this.excel_file);

            HTTP.post("/database/import-accounts", formData)
                .then(() => {
                    this.btnLoading = false;
                    this.$successMessage = "Les données ont été importées avec succès !";
                    this.close();
                    this.$emit("refresh");
                })
                .catch((err) => {
                    this.errors = err.response.data.errors;
                    this.$errorMessage = "Une erreur s'est produite veuillez corriger.";
                    this.btnLoading = false;
                    console.log(err);
                });
        },
        uploadFile(file) {
            this.excel_file = file;
        },
        download() {
            let path = process.env.VUE_APP_FILE_URL + '/accountsExample.xlsx'
            window.open(path, '_blanc')
        }
    },
};
</script>