<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            color="primary"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>


      <v-row>
        <v-col cols="12" lg="3">
          <v-card class="rounded-lg shadow">
            <v-card-title class="pb-1">
              <v-skeleton-loader v-if="loading" type="text" width="100"></v-skeleton-loader>
              <div v-else> {{ stat.total }}</div>
              <v-spacer></v-spacer>
              <v-avatar color="blue" size="40">
                <v-icon dark>mdi-account-group-outline</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text>
              Total points de vente
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3">
          <v-card class="rounded-lg shadow">
            <v-card-title class="pb-1">
              <v-skeleton-loader v-if="loading" type="text" width="100"></v-skeleton-loader>
              <div v-else> {{ stat.notip }}</div>
              <v-spacer></v-spacer>
              <v-avatar color="red" size="40">
                <v-icon dark>mdi-account-cancel-outline</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text>
              Total sans compte TIP
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3">
          <v-card class="rounded-lg shadow">
            <v-card-title class="pb-1">
              <v-skeleton-loader v-if="loading" type="text" width="100"></v-skeleton-loader>
              <div v-else> {{ stat.nogifty }}</div>
              <v-spacer></v-spacer>
              <v-avatar color="red" size="40">
                <v-icon dark>mdi-account-cancel-outline</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text>
              Total sans compte Gifty
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card :disabled="overlay" :loading="overlay" class="rounded-lg shadow">
            <v-card-title>

              <div>
                <v-text-field v-model="keyword" append-icon="mdi-magnify" class="w-300" clearable
                              dense
                              filled
                              hide-details
                              placeholder="Recherche..."
                              rounded single-line></v-text-field>
              </div>

              <v-spacer/>


              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-2" icon v-bind="attrs" @click="refresh" v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>

              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click="drawer = true"
                         v-on="on">
                    <v-icon>mdi-tune</v-icon>
                  </v-btn>
                </template>
                <span>Filtrer</span>
              </v-tooltip>


              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon
                         v-bind="attrs"
                         v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>

                  <v-list-item :disabled="!stat.notip" @click="$refs.createAccountsDialog.open()">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-account-multiple-plus-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Créér des comptes virtuel</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="addItem">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Ajouter un POS</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="downloadCanvas">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-download-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Télécharger le canvas</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="[
                                  importDialog = true,
                                  excel_file = '',
                                  $refs.excel_file ?  $refs.excel_file.reset() : false,
                                  info = '',
                                  errors = [],
                                  ExcelErrors = [],
                                  ]">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-upload-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Importer nouvelle BDD</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="ExportExcel">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-cloud-download-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Exporter</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="$refs.importAccountsDialog.open()">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-account-multiple-plus-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Importer des comptes TIP</v-list-item-title>
                  </v-list-item>

                </v-list>
              </v-menu>

            </v-card-title>
            <v-skeleton-loader v-if="loading" type="table"/>
            <v-card-text v-if="!loading && salepoints.length === 0" class="text-center">
              <NoResult/>
            </v-card-text>
            <div v-if="!loading && salepoints.length > 0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Statut</th>
                    <th>Photo</th>
                    <th id="key_27">{{ keywords.key_27 }}</th>
                    <th>Nom.APP</th>
                    <th>Installateur</th>
                    <th id="key_26">{{ keywords.key_26 }}</th>
                    <th id="key_28">Téléphone</th>
                    <th>Téléphone.APP</th>
                    <th>
                      <span id="key_3">{{ keywords.key_3 }}</span>
                    </th>
                    <th>D.inscription</th>
                    <th class="text-center">T.points</th>
                    <th class="text-center">C.Gifty</th>
                    <th class="text-center">C.TIP</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in salepoints" :key="item.id">

                    <td>
                      <v-switch v-model="item.is_active" class="mt-0 pt-0"
                                color="blue"
                                hide-details
                                readonly @click="changeStatus(item.id, item.is_active)"></v-switch>
                    </td>

                    <td>
                      <v-avatar size="38">
                        <v-img v-if="item.salepoint_account && item.salepoint_account.photo"
                               :src="FILE_URL + item.salepoint_account.photo"></v-img>
                        <v-img v-else :src="require('@/assets/avatar2.png')"></v-img>
                      </v-avatar>
                    </td>

                    <td>{{ item.name }}</td>

                    <td>
                      <span v-if="item.salepoint_account && item.salepoint_account.fullname">
                        {{ item.salepoint_account.fullname }}
                      </span>
                      <span v-else>-</span>
                    </td>

                    <td>
                      <span v-if="item.salepoint_account && item.salepoint_account.installer_name">
                        {{ item.salepoint_account.installer_name }}
                      </span>
                      <span v-else>-</span>
                    </td>

                    <td>{{ item.code }}</td>

                    <td>{{ item.phone }}</td>

                    <td>
                      <span v-if="item.salepoint_account && item.salepoint_account.salepoint_phone" class="info--text">
                        {{ item.salepoint_account.salepoint_phone }}
                      </span>
                      <span v-else>-</span>
                    </td>

                    <td>{{ item.city }}</td>

                    <td>
                       <span v-if="item.salepoint_account && item.salepoint_account.created_at">
                        {{ item.salepoint_account.created_at }}
                      </span>
                      <span v-else>-</span>
                    </td>

                    <td class="text-center">
                      <v-chip v-if="item.salepoint_account" color="blue" dark small>
                        {{ item.salepoint_account.points | toCurrency }}
                      </v-chip>
                      <v-chip v-else color="blue" dark disabled small>
                        0.00
                      </v-chip>
                    </td>

                    <td class="text-center">
                      <v-icon v-if="item.salepoint_account && item.salepoint_account.gifty_id" color="success">
                        mdi-check-circle
                      </v-icon>
                      <v-icon v-else color="error">mdi-close-circle</v-icon>
                    </td>

                    <td class="text-center">
                      <v-icon v-if="!item.salepoint_account" color="error">mdi-close-circle</v-icon>
                      <v-icon v-else color="success">mdi-check-circle</v-icon>
                    </td>

                    <td>
                      <v-menu bottom offset-y transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item @click="[salepointDetails = true,salepoint=item]">
                            <v-list-item-title>
                              <v-icon dense left>mdi-eye-plus</v-icon>
                              Voir plus
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="editItem(item)">
                            <v-list-item-title>
                              <v-icon dense left>mdi-pencil-outline</v-icon>
                              Modifier
                            </v-list-item-title>
                          </v-list-item>

                          <v-list-item @click="deleteLink(item)"
                                       v-if="item.salepoint_account && item.salepoint_account.gifty_id">
                            <v-list-item-title>
                              <v-icon dense left>mdi-link-off</v-icon>
                              Supprimer la liaison
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>

                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-divider v-if="total > 10"/>

              <v-card-actions v-if="total > 10">

                <div>
                  <v-select v-model="per_page"
                            :items="[10,20,50,100,200,300,400,500]"
                            :style="{width: '120px'}"
                            dense
                            hide-details
                            label="Ligne par page"
                            outlined></v-select>
                </div>

                <v-spacer/>
                <v-pagination v-if="total>10"
                              v-model="pagination.current"
                              :length="pagination.total"
                              circle
                              total-visible="6"
                              @input="onPageChange"
                ></v-pagination>
              </v-card-actions>

            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="importDialog" width="400">
        <v-card :loading="importBtnLoading">
          <v-card-title>
            Importer nouvelle BDD
            <v-spacer/>
            <v-icon @click="importDialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>
          <v-card-text class="pa-4">
            <v-alert v-if="info" color="warning" text>
              <p>{{ info }}</p>
              <div v-for="(errors,i) in ExcelErrors" :key="i">
                <ul>
                  <li v-for="(err,index) in  errors" :key="index">
                    <strong>Ligne : {{ err.row }}</strong> / {{ err.message }}
                  </li>
                </ul>
              </div>
            </v-alert>

            <v-file-input ref="excel_file"
                          :error-messages="errors.excel_file"
                          accept=".xlsx, .xls"
                          append-icon="mdi-file-excel"
                          dense
                          filled
                          hide-details="auto"
                          label="Fichier excel *"
                          rounded
                          show-size
                          small-chips
                          @change="uploadFile"
                          @click="errors && errors.excel_file ? errors.excel_file = '' : false"/>
            <div class="d-flex justify-space-between mt-3">
              <v-spacer/>
              <v-btn :loading="importBtnLoading" class="rounded-lg" color="primary" depressed
                     @click="importDatabase">
                <v-icon left>mdi-cloud-download-outline</v-icon>
                Importer
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-navigation-drawer v-model="drawer"
                           :permanent="drawer" app
                           clipped
                           floating
                           right
                           temporary width="400">
        <v-list-item>
          <v-list-item-avatar color="blue">
            <v-icon dark>mdi-tune</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Filtrages</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="drawer = false">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider/>
        <FilterDrawer ref="FilterDrawer" @filter="getSalepoints"/>
      </v-navigation-drawer>

      <SalepointForm ref="salepointForm"
                     :dialog.sync="salepointForm"
                     @get-salepoints="getSalepoints"/>

      <SalepointDetails v-if="salepoint"
                        :dialog.sync="salepointDetails"
                        :salepoint.sync="salepoint"/>

      <ImportAccountsDialog ref="importAccountsDialog" @refresh="refresh"/>

      <CreateAccountsDialog ref="createAccountsDialog" :notip="stat.notip" @refresh="refresh"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import FilterDrawer from "./FilterDrawer";
import SalepointForm from "./SalepointForm";
import SalepointDetails from "./SalepointDetails";
import ImportAccountsDialog from "./ImportAccountsDialog";
import NoResult from "../../components/NoResult";
import CreateAccountsDialog from "@/views/database/CreateAccountsDialog.vue";

export default {
  components: {CreateAccountsDialog, NoResult, FilterDrawer, SalepointForm, SalepointDetails, ImportAccountsDialog},
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
      status: true,
      overlay: false,
      loading: false,
      importDialog: false,
      salepointForm: false,
      salepointDetails: false,
      id: '',
      salepoints: [],
      total: 0,
      per_page: 10,
      pagination: {
        current: 1,
        total: 0
      },
      keyword: '',
      keywords: this.$store.state.keyword,
      drawer: false,
      importBtnLoading: false,
      excel_file: '',
      errors: [],
      ExcelErrors: [],
      info: '',
      salepoint: '',

      //filter
      filter: {
        have_account: 'all',
        have_tip_account: 'all',
        salepoint_platinum: 'all',
        cities: [],
      },
      stat: {
        total: 0,
        notip: 0,
        nogifty: 0,
      }
    }
  },
  methods: {
    uploadFile(file) {
      this.excel_file = file
    },
    importDatabase() {
      this.info = ''
      this.ExcelErrors = []
      this.importBtnLoading = true
      this.$Progress.start()
      let formData = new FormData()
      formData.append('excel_file', this.excel_file)
      HTTP.post('/database/import', formData).then((res) => {
        this.importBtnLoading = false
        this.$Progress.finish()
        this.$successMessage = 'Cette base de données a été importé avec succés'
        this.getSalepoints()
        if (res.data.status) {
          this.importDialog = false
          this.excel_file = ''
        } else {
          this.ExcelErrors = res.data.errors
          this.info = "Une erreur ç'est produit veuillez le corriger :"
        }
      }).catch(err => {
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        this.importBtnLoading = false
        console.log(err)
      })
    },
    editItem(item) {
      this.salepointForm = true
      this.$refs.salepointForm.editItem(item)
    },
    addItem() {
      this.salepointForm = true
      this.$refs.salepointForm.addItem()
    },
    getSalepoints(filter) {
      this.loading = true
      this.drawer = false
      this.$Progress.start()
      this.filter = filter ? filter : this.filter
      HTTP.get('/database?page=' + this.pagination.current, {
        params: {
          keyword: this.keyword,
          per_page: this.per_page,
          filter: this.filter
        }
      }).then((res) => {
        this.loading = false
        this.$Progress.finish()
        this.salepoints = res.data.data.data
        this.stat = res.data.stat
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;
      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },
    onPageChange() {
      this.getSalepoints();
    },
    downloadCanvas() {
      window.location.href = process.env.VUE_APP_FILE_URL + '/canvas.xlsx';
    },
    changeStatus(id, is_active) {
      this.$Progress.start()
      this.overlay = true
      let data = {
        id: id,
        is_active: !is_active,
      }
      HTTP.post('/database/change-status', data).then(() => {
        const i = this.salepoints.findIndex(item => item.id === id)
        this.salepoints[i].is_active = !is_active
        this.overlay = false
        this.$Progress.finish()
        this.$successMessage = 'Status changé avec succes !'
      }).catch(err => {
        console.log(err)
        this.$Progress.fail()
        this.overlay = false
      })
    },
    ExportExcel() {
      let baseUrl = process.env.VUE_APP_FILE_URL
      let token = this.$store.state.user.token
      let userId = this.$store.state.user.id
      let DownloadURL = baseUrl + '/export/excel/database?token=' + token + '&id=' + userId;
      window.open(DownloadURL, '_blanc')
    },
    refresh() {
      let filter = {
        have_account: 'all',
        have_tip_account: 'all',
        salepoint_platinum: 'all',
        cities: [],
      }
      this.$refs.FilterDrawer.refresh()
      this.getSalepoints(filter)
    },
    deleteLink(item) {
      this.id = item.salepoint_account_id
      this.$confirm_dialog = true
      this.$confirm_dialog_body = "Êtes-vous sûr de vouloir SUPPRIMER la liaison avec GIFTY de cet POS ?";
    }
  },
  created() {
    this.getSalepoints()
  },
  watch: {
    keyword() {
      this.getSalepoints();
    },
    per_page() {
      this.pagination.current = 1
      this.getSalepoints();
    },
    '$is_confirm': function (el) {
      if (el) {
        this.$is_confirm = false
        this.overlay = true
        this.$Progress.start()
        HTTP.delete('/database/delete-link/' + this.id).then(() => {

          const i = this.salepoints.findIndex(item => item.salepoint_account_id === this.id);

          if (i !== -1 && this.salepoints[i].salepoint_account) {
            this.salepoints[i].salepoint_account.gifty_id = null;
          }

          this.$successMessage = 'Action réussie!'
          this.overlay = false
          this.id = ''
          this.$Progress.finish()
        }).catch(err => {
          this.overlay = false
          this.$Progress.fail()
          console.log(err)
        })
      }
    },
  },
}
</script>

<style scoped>

</style>