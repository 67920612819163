<template>
  <div>
    <div class="pa-4">
      <label class="text--secondary">Compte Gifty</label>
      <v-radio-group v-model="filter.have_account"
                     class="mt-2"
                     row>
        <v-radio value="yes"
                 off-icon="mdi-checkbox-blank-circle-outline"
                 on-icon="mdi-checkbox-marked-circle"
                 label="Oui"></v-radio>
        <v-radio value="no"
                 off-icon="mdi-checkbox-blank-circle-outline"
                 on-icon="mdi-checkbox-marked-circle"
                 label="Non"></v-radio>
        <v-radio value="all"
                 off-icon="mdi-checkbox-blank-circle-outline"
                 on-icon="mdi-checkbox-marked-circle"
                 label="Tous"></v-radio>
      </v-radio-group>
      <label class="text--secondary">Compte TIP</label>
      <v-radio-group v-model="filter.have_tip_account"
                     class="mt-2"
                     row>
        <v-radio value="yes"
                 off-icon="mdi-checkbox-blank-circle-outline"
                 on-icon="mdi-checkbox-marked-circle"
                 label="Oui"></v-radio>
        <v-radio value="no"
                 off-icon="mdi-checkbox-blank-circle-outline"
                 on-icon="mdi-checkbox-marked-circle"
                 label="Non"></v-radio>
        <v-radio value="all"
                 off-icon="mdi-checkbox-blank-circle-outline"
                 on-icon="mdi-checkbox-marked-circle"
                 label="Tous"></v-radio>
      </v-radio-group>
      <v-select
          :items="sums"
          v-model="filter.sum"
          item-text="name"
          item-value="value"
          clearable
          dense
          filled
          hide-details
          id="key_9"
          :label="keyword.key_9"
          rounded
          class="rounded mb-2"/>
      <v-select
          :items="filter_elements.types"
          v-model="filter.types"
          item-text="name"
          item-value="name"
          multiple
          chips
          small-chips
          deletable-chips
          dense
          filled
          hide-details
          id="key_1"
          :label="keyword.key_1"
          rounded
          class="rounded mb-2"/>

      <v-select
          :items="filter_elements.regions"
          v-model="filter.regions"
          item-text="name"
          item-value="name"
          multiple
          chips
          small-chips
          deletable-chips
          dense
          filled
          hide-details
          id="key_2"
          :label="keyword.key_2"
          rounded
          class="rounded mb-2"/>

      <v-autocomplete
          :items="cities"
          v-model="filter.cities"
          item-text="name"
          item-value="name"
          multiple
          chips
          small-chips
          deletable-chips
          dense
          filled
          hide-details
          id="key_3"
          :label="keyword.key_3"
          rounded
          class="rounded mb-2">
        <template v-slot:prepend-item>
          <v-list-item ripple @mousedown.prevent @click="toggle">
            <v-list-item-action>
              <v-icon :color="filter.cities.length > 0 ? 'primary' : ''">
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Sélectionner tout
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-autocomplete>

      <v-select
          :items="filter_elements.municipalities"
          v-model="filter.municipalities"
          item-text="name"
          item-value="name"
          multiple
          chips
          small-chips
          deletable-chips
          dense
          filled
          hide-details
          id="key_4"
          :label="keyword.key_4"
          rounded
          class="rounded mb-2"/>

      <v-select
          :items="filter_elements.b_roles"
          v-model="filter.b_roles"
          item-text="name"
          item-value="name"
          multiple
          chips
          small-chips
          deletable-chips
          dense
          filled
          hide-details
          id="key_5"
          :label="keyword.key_5"
          rounded
          class="rounded mb-2"/>

      <v-select
          :items="filter_elements.c_roles"
          v-model="filter.c_roles"
          item-text="name"
          item-value="name"
          multiple
          chips
          small-chips
          deletable-chips
          dense
          filled
          hide-details
          id="key_6"
          :label="keyword.key_6"
          rounded
          class="rounded mb-4"/>

      <label class="text--secondary" id="key_7">{{ keyword.key_7 }}</label>
      <v-radio-group v-model="filter.salepoint_platinum"
                     class="mt-2"
                     row>
        <v-radio value="yes"
                 off-icon="mdi-checkbox-blank-circle-outline"
                 on-icon="mdi-checkbox-marked-circle"
                 label="Oui"></v-radio>
        <v-radio value="no"
                 off-icon="mdi-checkbox-blank-circle-outline"
                 on-icon="mdi-checkbox-marked-circle"
                 label="Non"></v-radio>
        <v-radio value="all"
                 off-icon="mdi-checkbox-blank-circle-outline"
                 on-icon="mdi-checkbox-marked-circle"
                 label="Tous"></v-radio>
      </v-radio-group>

      <v-select
          :items="filter_elements.classes"
          v-model="filter.classes"
          item-text="name"
          item-value="name"
          multiple
          chips
          small-chips
          deletable-chips
          dense
          filled
          hide-details
          id="key_8"
          :label="keyword.key_8"
          rounded
          class="rounded mb-2"/>
      <v-btn block color="primary" depressed class="rounded-lg" large @click="onFilter">
        Filtrer
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: {
        have_account: 'all',
        have_tip_account: 'all',
        salepoint_platinum: 'all',
        cities: [],
      },
      sums: [
        {name: this.$store.state.keyword.key_10, value: 'sum_of_M_106'},
        {name: this.$store.state.keyword.key_11, value: 'sum_of_M_70'},
        {name: this.$store.state.keyword.key_12, value: 'sum_of_ULT'},
        {name: this.$store.state.keyword.key_13, value: 'sum_of_HSP'},
        {name: this.$store.state.keyword.key_14, value: 'sum_of_Con92_1'},
        {name: this.$store.state.keyword.key_15, value: 'sum_of_Con92_2'},
        {name: this.$store.state.keyword.key_16, value: 'sum_of_OV_GM_1'},
        {name: this.$store.state.keyword.key_17, value: 'sum_of_OV_GM_2'},
        {name: this.$store.state.keyword.key_18, value: 'sum_of_OV_PM'},
        {name: this.$store.state.keyword.key_19, value: 'sum_of_Disps'},
        {name: this.$store.state.keyword.key_20, value: 'sum_of_Waf_66'},
        {name: this.$store.state.keyword.key_21, value: 'sum_of_Waf_99'},
        {name: this.$store.state.keyword.key_22, value: 'sum_of_M_M'},
        {name: this.$store.state.keyword.key_23, value: 'sum_of_M_C1'},
        {name: this.$store.state.keyword.key_24, value: 'sum_of_M_C2'},
        {name: this.$store.state.keyword.key_25, value: 'sum_of_M_C3'},
      ]
    }
  },
  methods: {
    onFilter() {
      this.$emit('filter', this.filter)
    },
    refresh() {
      this.filter = {
        have_account: 'all',
        have_tip_account: 'all',
        salepoint_platinum: 'all',
        cities: [],
      }
    },
    toggle() {
      if (this.filter.cities.length === this.cities.length) {
        this.filter.cities = []
      } else {
        this.filter.cities = this.cities.map(el => el.name)
      }
    },
  },
  computed: {
    filter_elements() {
      return this.$store.state.salepoint_filter_elements
    },
    cities() {
      return this.$store.state.cities
    },
    keyword() {
      return this.$store.state.keyword
    },
    icon() {
      if (this.filter.cities.length === this.cities.length) return 'mdi-close-box'
      if (this.filter.cities.length > 0 && this.filter.cities.length !== this.cities.length) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  }
}
</script>

<style scoped>

</style>