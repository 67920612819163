<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="400">
      <form @submit.prevent="save">
        <v-card :disabled="isLoading" :loading="isLoading" class="text-center">

          <v-card-text class="pa-6">

            <v-icon color="primary" size="110">mdi-information-outline</v-icon>

            <h2 class="text--primary mt-3">Créér des comptes virtuel</h2>

            <p class="mt-5 mb-5">
              Ce processus crée des comptes virtuels pour tous les points de vente qui n'ont pas de compte TIP.

              <strong class="primary--text d-block mt-2">
                  {{ notip }} Sans compte TIP
             </strong>
            </p>

            <v-btn class="mr-2" text
                   @click="dialog = false">
              <v-icon left>mdi-close</v-icon>
              Fermer
            </v-btn>

            <v-btn :loading="isLoading"
                   color="primary"
                   depressed
                   type="submit"
                   @click="[$is_confirm = true,$confirm_dialog = false]">
              <v-icon left>mdi-content-save</v-icon>
              Créer
            </v-btn>

          </v-card-text>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    props: ['notip'],
    data() {
        return {
            dialog: false,
            isLoading: false,
            errors: {},
        };
    },
    methods: {
        open() {
            this.errors = {}
            this.dialog = true
        },
        save() {
            this.errors = {}
            this.isLoading = true

            HTTP.post("/database/create-accounts")
                .then(() => {
                    this.isLoading = false
                    this.$successMessage = "Les données ont été importées avec succès !"
                    this.dialog = false
                    this.$emit("refresh")
                })
                .catch((err) => {
                    this.errors = err.response.data.errors
                    this.$errorMessage = "Une erreur s'est produite veuillez corriger."
                    this.isLoading = false
                    console.log(err)
                });
        }
    }
};
</script>